import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ContactForm from './ContactForm';

const Popup = () => {
  const [show, setShow] = useState(false);
  const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

  // Static offer end date in UTC+12:00 timezone
  const staticEndDate = new Date('2024-09-30T11:59:00+12:00').toISOString();

  const calculateTimeLeft = (endDate) => {
    const now = new Date();
    const difference = +new Date(endDate) - +now;

    if (difference > 0) {
      return {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }
  };

  useEffect(() => {
    // Set the countdown to the static end date
    const timer = setInterval(() => {
      const timeLeftNow = calculateTimeLeft(staticEndDate);
      setTimeLeft(timeLeftNow);
    }, 1000);

    // Automatically show popup once per session after 10 seconds
    const hasPopupShown = sessionStorage.getItem('popupShown');

    if (!hasPopupShown) {
      const popupTimer = setTimeout(() => {
        setShow(true);
        sessionStorage.setItem('popupShown', 'true');
      }, 5000);

      return () => clearTimeout(popupTimer);
    }

    return () => clearInterval(timer); // Cleanup interval on component unmount
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div>
      {/* Floating Button */}
      <Button 
        className='bounce'
        onClick={handleShow} 
        style={{
          position: 'fixed',
          bottom: '25px',
          right: '30px',
          width: '70px',
          height: '70px',
          borderRadius: '50%',
          fontWeight:'bold',
          backgroundColor: '#d945af',
          color: 'white',
          border: 'none',
          zIndex: 9999,
          outline: "3px solid white"
        }}
      >
        Save<br/>20%
      </Button>

      {/* Popup Modal */}
      <Modal show={show} onHide={handleClose} centered dialogClassName="custom-modal-width">
        <Modal.Body style={{ maxHeight: '600px', padding: 0 }}>
          
          {/* Countdown Timer */}
          {/* <div style={{ padding: '10px', textAlign: 'center', marginBottom: '10px', backgroundColor: "#a94c8f" }}>
            <h5 className='text-white' style={{display:'inline-block',verticalAlign: 'middle', margin: 0, marginRight:'15px' }}>Offer ends in:</h5>
            <h4 className='text-white' style={{display:'inline-block',verticalAlign: 'middle', margin: 0 }}>
              {timeLeft.days}d {timeLeft.hours}h {timeLeft.minutes}m {timeLeft.seconds}s
            </h4>
          </div> */}

          {/* Close Button */}
          <Button 
            variant="secondary" 
            onClick={handleClose} 
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              border: 'none',
              fontSize: '22px',
              backgroundColor: 'transparent',
              color: '#333'
            }}
          >
            &times;
          </Button>
          
          <div className="d-flex" style={{padding:"20px 20px 30px" }}>
            {/* Image on the left */}
            <div className='popup-image-wr' style={{ flex: 1 }}>
              <img
                src="/img/offer-2.png" 
                alt="Placeholder"
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </div>

            {/* Form on the right */}
            <div className='popup-form-wr' style={{ flex: 1, padding: '0 0px 0 20px' }}>
              <h2 className='text-center mb-3'>Grab this offer</h2>
              <ContactForm class='popup-form' btnid='popup-submit-btn' />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Popup;
